import React, { useState } from "react";
import { Link } from "react-scroll";
import style from "./NavBar.module.scss";
import { NavLink } from "react-router-dom";
import { Navbar, Container, Nav } from "react-bootstrap";
import {
  RiLinkedinLine,
  RiInstagramLine,
  RiGithubLine,
  RiFacebookLine,
} from "react-icons/ri";

const NavBar = ({ dimensions }) => {
  const [nav, setNav] = useState(false);
  const scrollValue = dimensions >= 770 ? 100 : 10;

  const changeBackground = () => {
    if (window.scrollY >= scrollValue) {
      setNav(true);
    } else {
      setNav(false);
    }
  };
  window.addEventListener("scroll", changeBackground);

  const handleToggle = (isOpen) => {
    setNav(isOpen);
  };

  return (
    <div>
      <Navbar
        className={nav ? style.color : style.trasp}
        collapseOnSelect
        expand="md"
        fixed="top"
        variant="dark"
        onToggle={handleToggle}
      >
        <Container fluid className={nav ? style.espace : style.cntCon}>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />{" "}
          <Link
            className={style.navLinkDH}
            to="home"
            smooth={true}
            duration={500}
          >
            DavidHeredia
          </Link>
          <Navbar.Collapse className={style.navCss} id="responsive-navbar-nav">
            <Nav className={style.ctnNav1}>
              <Link
                className={style.navLink}
                to="home"
                smooth={true}
                duration={500}
              >
                HOME
              </Link>
              <Link
                className={style.navLink}
                to="experience"
                smooth={true}
                duration={500}
              >
                EXPERIENCE
              </Link>
              <Link
                className={style.navLink}
                to="projects"
                smooth={true}
                duration={500}
              >
                PROJECTS
              </Link>

              <Link
                className={style.navLink}
                to="skills"
                smooth={true}
                duration={500}
              >
                SKILLS
              </Link>
              <Link
                className={style.navLink}
                to="contact"
                smooth={true}
                duration={500}
              >
                CONTACT
              </Link>
              <NavLink
                to="./download-curriculum-vitae"
                target="_blank"
                className={style.navLink}
              >
                {" "}
                DOWNLOAD CV
              </NavLink>
            </Nav>

            <Nav>
              <a
                className={style.navLink1}
                href="https://www.linkedin.com/in/david-heredia-91625b24/"
                target="_blank"
                rel="noreferrer"
              >
                <RiLinkedinLine className={style.icon} />
              </a>
              <a
                className={style.navLink1}
                href="https://github.com/davidheredia25"
                target="_blank"
                rel="noreferrer"
              >
                <RiGithubLine className={style.icon} />
              </a>
              <a
                className={style.navLink1}
                href="https://www.instagram.com/deividheredia/"
                target="_blank"
                rel="noreferrer"
              >
                <RiInstagramLine className={style.icon} />
              </a>
              <a
                className={style.navLink1}
                href="https://www.facebook.com/davis.heredia/"
                target="_blank"
                rel="noreferrer"
              >
                <RiFacebookLine className={style.icon} />
              </a>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavBar;
